import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  GetContextMenu(params) {
    return http.instanceCommon.get("/Common/GetContextMenu?" + params);
  }
  async CheckPrivilege(params) {
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async GetDDLData(params) {
    return await http.instanceCommon.get("/Common/GetDDLData?" + params);
  }
  async GetScreenShotListing(params) {
    return await http.instance.get("/GetScreenShotListing?" + params);
  }

  async GetTimeTrackingHistoryListing(params) {
    return await http.instance.get("/GetWebApplicationHistoryListing?" + params);
  }

  async GetDropdownlistApplicationNotAssigned(params) {
    return await http.instance.get("/GetDropdownlistApplicationNotAssigned?" + params);
  }
  async GetDepartmentList() {
    return await http.instanceCommon.get("/Common/GetDepartmentList?");
  }
  async SaveAssignDepartment(params) {
    return await http.instance.post("/SaveAssignDepartment", { postString: params });
  }
  async Delete(param) {
    return await http.instanceCommon.get("/Common/Delete?" + param);
  }
  async GetAssignDepartmentByApplicationId(params) {
    return await http.instance.get("/GetAssignDepartmentByApplicationId?" + params);
  }
  async GetCategoryListing(params) {
    return await http.instance.get("/GetCategoryListing?" + params);
  }
  async ManageCategory(params) {
    return await http.instance.post("/ManageCategory", { postString: params });
  }
  async EditBulkTimeTrackEntry(params) {
    return await http.instance.post("/EditBulkTimeTrackEntry", { postString: params });
  }
  async GetCategoryById(params) {
    return await http.instance.get("/GetCategoryById?" + params);
  }

  async TimerDashboard(params) {
    return await http.instance.get("/TimerDashboard?" + params);
  }

  async getArrivalTimeDashboard(params) {
    return await http.instance.get("/AverageTimeBarDashboard?" + params)

  }
  async GetTimerProjectsByUserID(param) {
    return await http.instance.get("/GetTimerProjectsByUserID?" + param)
  }

  async GetAllApps(params) {
    return await http.instance.get("/GetAllApps?" + params);
  }
  async GetMostUsedApps(params) {
    return await http.instance.get("/GetMostUsedApps?" + params);
  }
  async GetUsedAppTimeDurationByCategories(params) {
    return await http.instance.get("/GetUsedAppTimeDurationByCategories?" + params);
  }
  async GetTimerListing(params) {
    return await http.instance.get("/GetTimerListing?" + params);
  }
  async LoadTask(params) {
    return await http.instance.get("/LoadTask?" + params);
  }
  async GetCompanyTaglist(params) {
    return await http.instance.get("/GetCompanyTaglist?" + params);
  }
  async GetProductiveEmployees(params) {
    return await http.instance.get("/GetProductiveEmployees?" + params);
  }
  async GetIncompleteEntry() {
    return await http.instance.get("/GetIncompleteEntry");
  }
  // async GetIncompleteEntry(){
  //   return await http.instance.get("/GetIncompleteEntry");
  // }
  async SaveProjectInfo(info) {
    return await http.instanceModals.post("/SaveTimeEntry", info);
  }
  async GetDepartmentwithUsers(params) {
    return await http.instance.get("/GetDepartmentWithUsers?" + params);
  }
  async GetTimerListingByTrackId(info) {
    return await http.instance.get("/GetTimerListingByTrackId" + info);
  }
  async LoadTasks(param) {
    return await http.instance.get("/LoadTask?" + param);
  }
  async setAction(params) {
    return await http.instance.post("/UpdateOrDeleteEntries?" + params);
  }
  async GetBreakTimeList() {
    return await http.instance.get("/GetAllShiftBreakListing");
  }
  async getUserByProjectIdAndTaskId(params) {
    return await http.instance.get("/GetUserListByProjectIdAndTaskId?" + params);
  }
  async SubmitBulkTimeEntry(params) {
    debugger;
    return await http.instance.post("/BulkTimeEntry", { postString: params });
  }
  // async SubmitBulkTimeEntry(params){
  //   debugger;
  //   return await http.instance.post("/BulkTimeEntry?" + params );
  // }
  async GetToDoListing(params) {
    return await http.instanceEvent.get("/Project/ToDoList?" + params);
  }
  async TimeTrackWeeklyDataList(params) {
    //debugger
    return await http.instanceEvent.get("/Project/TimerWeeklyHoursListing?" + params);
  }
  async AddManually(data) {
    return await http.instanceModals.post("/AddManually", data);
  }
  async DeleteByTrackId(data) {
    return await http.instance.post("/DeleteByTrackId?" + data);
  }
  async SaveTagPopUp(data) {
    return await http.instanceModals.post("/SaveTagPopUp", data);
  }

  // async GetTimeTrackingHistoryListing(params){
  //   return await http.instance.get("/GetWebApplicationHistoryListing?" + params);
  // }
  async GetUserNameandshift(params) {
    return await http.instance.get("/GetShiftDetailsByUsersId?" + params);
  }
  async GetAllData(params) {
    return await http.instance.get("/WorkDiary?" + params);
  }
  GetClockedHours(params) {
    return http.instance.get("/GetClockedHours?" + params);
  }
  GetApplicationTracking(params) {
    return http.instance.get("/GetApplicationTracking?" + params);
  }
  Deletescreenshots(params) {
    return http.instance.delete("/Deletescreenshots?" + params);
  }
  FlagScreenShots(params) {
    return http.instance.get("/FlagScreenShots?" + params);
  }
  PostComment(params) {
    return http.instance.post("/PostComment?" + params);
  }
  GetComments(params) {
    return http.instance.get("/GetComments?" + params);
  }
  GetFlagScreenshotListing(params) {
    return http.instance.get("/GetFlagScreenshotListing?" + params);
  }
  GetEventsPerMinutes(params) {
    return http.instance.get("/GetEventsPerMinutes?" + params);
  }
  GetDisplayScreen(params) {
    return http.instance.get("/GetDisplayScreen?" + params);
  }
  GetScreenshotDates(params) {
    return http.instance.get("/GetScreenshotDates?" + params);
  }
  GetUsersByCompanyId() {
    return http.instance.get("/GetUsersByCompanyId");
  }
  GetConfigUsersByCompanyId() {
    return http.instance.get("/GetConfigUsersByCompanyId");
  }
  ListTeamUsersByPM() {
    return http.instance.get("/ListTeamUsersByPM");
  }
  GetProjectListByUserIdAndUserType() {
    return http.instance.get("/GetProjectListByUserIdAndUserType");
  }
  async GetUsersForTimesheet(params) {
    return await http.instance.get("/GetUsersForTimesheet?" + params);
  }
  async ListTimesheetForWeek(params) {
    return await http.instance.get("/GetTimesheetDetails?" + params);
  }
  async ListTimesheetForWeekNew(params) {
    return await http.instance.get("/GetTimesheetDetailsNew?" + params);
  }
  async DeleteTrackEntryByProjectAndTaskId(param) {
    return await http.instance.get("/DeleteTrackEntryByProjectAndTaskId?" + param);
  }
  async TimerWeeklyTask() {
    return await http.instance.get("/TimerWeeklyTask");
  }
  async GetStartEndDate(param) {
    return await http.instance.get("/GetStartEndDate?" + param);
  }
  async GetPastActivityDetails(params) {
    return await http.instanceEvent.get("/Project/GetPastActivityDetails?" + params);
  }
  async UpdateBillableEntry(params) {
    return await http.instanceEvent.get("/Project/UpdateBillableEntry?" + params);
  }
  async DeleteTimeEntry(params) {
    return await http.instanceEvent.get("/Project/DeleteTimeEntry?" + params);
  }
  async RequestTime(params) {
    return await http.instanceEvent.post("/Project/RequestTime", { postString: params });
  }
  async Comments(params) {
    return await http.instanceEvent.post("/Project/SaveCommentLog", { postString: params });
  }
  async FileListing(params) {
    return await http.instanceEvent.get("/Project/FileListing?" + params);
  }
  async GetQuickViewByProjectIdTaskId(params) {
    return await http.instanceEvent.get("/Project/GetQuickViewByProjectIdTaskId?" + params);
  }
  async ClientsGetFromProject(params) {
    return await http.instanceEvent.get("/Project/GetClientTeam?projectId=" + params);
  }
  async PMGetfromProject(params) {
    return await http.instanceEvent.get("/Project/GetPMTeam?projectId=" + params);
  }
  async GetAllApp(params) {
    return await http.instance.get("/GetAllApp?" + params);
  }
  async UpdateProductiveType(params) {
    return await http.instance.post("/UpdateProductiveType?" + params);
  }
  async AddManuallyBreak(params) {
    return await http.instanceModals.post("/ManualBreak", params);
  }

  async SaveTimeSheetEntry(params) {
    return http.instance.post("/SaveTimeSheetEntry?", { jsonData: params });
  }
  CommonDelete(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { postString: params })
  }
  async NoteListingByProjectaAndTask(params) {
    return await http.instanceEvent.get("/Project/NoteListingByProjectaAndTask?" + params);
  }
  async saveNote(params) {
    return await http.instanceEvent.post("/Project/SaveNote", { postString: params })
  }
  async LoadTaskByProjectId(params) {
    return await http.instanceEvent.get("/Project/LoadTaskByProjectIdForTodolist?" + params);
  }
  async GetShowLocationsAndMap(params) {
    return await http.instanceEvent.get("/Project/GetShowLocationsAndMap?" + params);
  }
  async UpdateProgress(params) {
    return await http.instanceEvent.post("/Project/SaveTaskLog", { postString: params });
  }
  async GetChecboxUserData(params) {
    return await http.instanceEvent.get("/Project/UserList?" + params);
  }
  async Create(params) {
    return await http.instanceEvent.post("/Project/Create", params);
  }
  CommentListing(params) {
    return http.instanceEvent.get("/Project/RequestTimeCommentDetail?" + params);
  }
  async TaskLogCommentDetail(params) {
    return await http.instanceEvent.get("/Project/TaskLogCommentListing?" + params);
  }

  async PasteLastWeekTimesheet(params) {
    return http.instance.post("/PasteLastWeekTimesheet?" + params);
  }

  async TimerTeamStatus(params) {
    return http.instance.get("/TimerWeeklyTeamStatus?" + params);
  }

  async GetProjectWithTasks() {
    return http.instance.get("/GetProjectWithTasks");
  }
  async TrackEntryFlag(params) {
    return http.instance.get("/TrackEntryFlag?" + params);
  }
  ReportMain(params) {
    return http.instanceCommon.post("/Report/ReportMain", { postString: params });
  }
  GetCommentListBytrackId(params) {
    return http.instanceEvent.get("/Project/CommentList?" + params);
  }
  FlagsPostComment(params) {
    return http.instance.post("/FlagPostComment", { postString: params });
  }
  UpdateTimeTrackEntry(params) {
    return http.instanceModals.post("/UpdateTimeTrackEntry", params);
  }
  ImportCategorySamplePost(param) {
    return http.instance.post("/ImportCategorySamplePost/", param);
  }
  BulkUploadCategoryType(params) {
    return http.instance.get("/BulkUploadCategoryType?" + params);
  }
  async CategoryTypeBulkUploadPost(params) {
    return await http.instance.post("/CategoryTypeBulkUploadPost", params);
  }
  UpdateStatus(params) {
    return http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
  }
  StatusDropdown(params) {
    return http.instanceEvent.get("/Project/StatusListing?" + params);
  }
  async GetModuleMasterIdByModuleName(params) {
    return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?" + params);
  }
  async GetConfigurationValueForCompanyByKey(param) {
    return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?" + param);
  }
  async GetHideContextMenuConfig() {
    return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async TimesheetApprovalList(params) {
    return await http.instance.get("/TimesheetList?" + params);
  }
  SharedUsersParentListByParentId(params) {
    return http.instance.get("/docSharedUsersListByParentId?", { params });
  }
  SharedUsersParentList(params) {
    return http.docstrage.get("/docSharedUsersParentList?", { params });
  }

  async SaveHideContextMenuConfig(param) {
    return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?" + param);
  }
  async CopyLastWeekTimesheet(param) {
    return http.instance.post("/CopyLastWeekTimesheet?" + param);
  }
  async CopyToUserWeekTimesheet(param) {
    return http.instance.post("/CopyToUserWeekTimesheet?" + param);
  }

  async GetTimerUserTaskView(params) {
    return await http.instance.get("/TimerUserTaskView?" + params);
  }
  async SendForApproval(params) {
    return http.instance.post("/SendForApproval?" + params);
  }
  async DeleteTimeSheetRecord(params) {
    return http.instance.post("/DeleteMultipleTrackEntry", { jsonData: params });
  }
  async TimesheetHistoryList(params) {
    return await http.instance.get("/TimesheetHistoryList?" + params);
  }
  async GetTimesheetStatus(params) {
    return await http.instance.get("/GetTimesheetStatus?" + params);
  }
  async GetHolidayDate(params) {
    return await http.instance.get("/GetHolidayDate?" + params);
  }
  async GetCommentListByWeekDateRange(params) {
    return await http.instance.get("/GetCommentListByWeekDateRange?" + params);
  }
  ManagePin_UpPin(params) {
    return http.instanceCommon.post("/Common/ManagePin_UpPin?postString=" + params);
  }
  async AddTimesheetComment(url) {

    return await http.instance.post("/AddTimesheetComment?" + url);
  }
  async PostNewCommentForRollback(url) {

    return await http.instance.post("/PostNewCommentForRollback?" + url);
  }
  async AddApproverStatus(url) {

    return await http.instance.post("/AddApproverStatus?" + url);
  }
  async getTrackedtimings(url) {

    return await http.instance.get("/TrackedTime?" + url);
  }
  async GetBreaksDetails(url) {

    return await http.instance.get("/GetBreaksDetails?" + url);
  }
  async TimerMyStatus(params) {
    return http.instance.get("/TimerWeeklyMyStatus?" + params);
  }
  async GetEnableDisableFeature(params) {
    return await http.instanceCommon.get("/AdvanceFeatur/GetEnableDisableFeature?" + params);
  }

  SaveEnableDisableFeature(params) {
    return http.instanceCommon.post("/AdvanceFeatur/SaveEnableDisableFeature", params);
  }
  GetConfigurationData(params) {
    return http.instanceCommon.get("/Common/GetModuleKeys?" + params);
  }
  async GetAllModule(params) {
    return await http.instanceCommon.get("/Configuration/GetAllModule?" + params);
  }
  async GetKeys(params) {
    return await http.instanceCommon.get("/Configuration/GetKeys?" + params);
  }
  async CheckConfigurations(params) {
    return http.instance.get("/CheckConfigurations?" + params);
  }
  async ModuleValidationMessages(params) {
    return await http.instanceCommon.get("/Configuration/ModuleValidationMessages?" + params);
  }
  async UpdateCompanySetupConfig(params) {
    return await http.instanceCommon.post("/Configuration/Update", params);
  }
  async GetApprovalGroupByCompanyId() {
    return await http.instanceApproval.get("/ApprovalChain/GetApprovalGroupByCompanyId?");
  }
  async GetByChainId(param) {
    return await http.instanceApproval.get("/ApprovalChain/GetByChainId?" + param);
  }
  async LoadUsersByCompanyId(param) {
    return await http.instanceApproval.get("/ApprovalRule/LoadUsersByCompanyId?" + param);
  }
  async CheckDuplicate(param) {
    return await http.instanceApproval.get("/ApprovalChain/CheckDuplicate?" + param);
  }
  async AddGroup(param) {
    return await http.instanceApproval.post("/ApprovalGroup/AddGroup?", { postString: param });
  }
  async AddApprovalChain(param) {
    return await http.instanceApproval.post("/ApprovalChain/AddApprovalChain?", { postString: param });
  }
  async GetLocationByCompanyId(param) {
    return await http.instanceApproval.get("/ApprovalRule/GetLocationByCompanyId?" + param);
  }
  async GetProjectListing(param) {
    return await http.instanceApproval.get("/ApprovalRule/GetProjectList?" + param);
  }
  async GetApprovalChainsByCompanyId() {
    return await http.instanceApproval.get("/ApprovalRule/GetApprovalChainsByCompanyId?");
  }
  async GetRuleById(param) {
    return await http.instanceApproval.get("/ApprovalRule/GetRuleById?" + param);
  }
  async GetUsersByProjectId(param) {
    return await http.instanceApproval.get("/ApprovalRule/LoadUsersByProjectId?" + param);
  }
  async AddRule(poststring) {
    return await http.instanceApproval.post("/ApprovalRule/AddRule?", { postString: poststring });
  }
  async ApprovalGroupListByApprovalCHainId(param) {
    return await http.instanceApproval.get("/ApprovalRule/GroupList?" + param);
  }
  async GetAllShifts() {
    return await http.instanceCommon.get("/Wizard/GetAllShift");
  }
  async WeekDetail(param) {
    return await http.instance.get("/WeekDetail?" + param);
  }
  async WorkingDay(param) {
    return await http.instance.get("/WorkingDay?" + param);
  }
  async ListWorkDay(param) {
    return await http.instance.get("/ListWorkDay?" + param);
  }
  async DeleteWoringDay(param) {
    return await http.instance.post("/DeleteDayStatus?" + param);
  }
  async GetCompanyLocation() {
    return await http.instanceCommon.get("/Configuration/GetCompanyLocation");
  }
  async GetUserListByLocationId(params) {
    return await http.instance.get("/GetUserListByLocationId?" + params);
  }
  async Manage(param) {
    return await http.instance.post("/Manage", param);
  }
  HolidayBankListing(params) {
    return http.instanceCompanysetup.get("/CompanyHoliday/GetHolidayBankListing?" + params);
  }
  CompanyHolidayMultipleLocation(params) {
    return http.instanceCompanysetup.get("/CompanyHoliday/GetMultiLocationByCompanyId?" + params);
  }
  AddHolidayInBank(params, obj) {
    return http.instanceCompanysetup.post("/CompanyHoliday/AddHolidayInBank?" + params, { postString: obj });
  }
  SendAttactment(params) {
    return http.docstrage.post("/UploadStreamNew", params);
  }
  async Post(params) {
    return await http.instanceEvent.post("/Project/Post", params);
  }
  GetBlobUrl(params) {
    return http.instanceCommon.get("/Common/GetBlobUrl?" + params);
  }
  GetTimeZone() {
    return http.instanceCommon.get("/HelpAndGuide/GetTimeZone")
  }
  GetCurrency(params) {
    return http.instanceCommon.get("/Common/GetCurrency");
  }
  GetTimerStatusDetail() {
    return http.instance.get("GetTimerStorageSetupDetail");
  }
  AddUpdateWorkWeek(params) {
    return http.instance.post("AddUpdateWorkWeek?" + params);
  }
  async GetTagListing(params) {
    return http.instance.get("GetCompanyTagsDetail?" + params);
  }
  CommonUpdateStatus(params) {
    return http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
  }
  async DownloadFile(params) {
    return http.docstrage.get("/DownloadFileSingle?" + params, { responseType: "blob" });
  }
  GetStatusIdByCode(param) {
    return http.instanceCommon.get("/Common/GetStatusIdByCode?" + param);
  }
  async GetLiveUsers(params) {
    return await http.instance.get("/GetLiveUsers?" + params);
  }
  async GetUserImageByUserId(param) {
    return await http.instanceCommon.get("/Common/GetUserImageByUserId?" + param);
  }
  GetScreenshotPath(param) {
    return http.instanceCommon.get("/Common/GetScreenshotPath?" + param);
  }
  GetFreeTrialSettings() {
    return http.instanceCommon.get("/Configuration/GetFreeTrialSettings");
  }

  GetRemainingFreeTrailDays() {
    return http.instanceCommon.get("/Configuration/GetRemainingFreeTrailDays");
  }

  GetProgresSetupStatusDetail(param) {
    return http.instanceCommon.get("/Common/GetProgresSetupStatusDetail?" + param);
  }

  UpdateLinkVisitedInformation(param) {
    return http.instanceCommon.get("/Common/UpdateLinkVisitedInformation?" + param);
  }
  UpdateUserSettingConfig(param) {
    return http.instanceCommon.post("/Common/UpdateUserSettingConfig?" + param);
  }
  GetUserSettingConfigByType(param) {
    return http.instanceCommon.get("/Common/GetUserSettingConfigByType?" + param);
  }

  CheckFreeTrialEligibilityForCA() {
    return http.instanceCommon.get("/Configuration/CheckFreeTrialEligibilityForCA");
  }

  ImportSampleData() {
    return http.instanceCommon.post("/Common/ImportSampleData?");
  }

  DeleteSampleData() {
    return http.instanceCommon.post("/Common/DeleteSampleData?");
  }

  CheckSampleDataImportOrNot(params) {
    return http.instanceCommon.get("/Common/CheckSampleDataImportOrNot?" + params);
  }
  async GetChildScreenshotById(params){
    return http.instance.get("/GetChildScreenshotById?" + params);
  }
  
  async GetDeviceListByCompanyId(params) {
    return await http.instance.get("/GetDeviceListByCompanyId?" + params);
  }
  async GetProfileDDl(param){
    return await http.instance.get("/GetProfileDDl?" + param);
  }
  async IsMappingAlreadyExists(param){
    return await http.instance.get("/IsMappingAlreadyExists?" + param);
  }

async MapUser(param){
  return await http.instance.post("/MapUser?" + param);
}

async GetProfileListByMachineId(param){
  return await http.instance.get("/GetProfileListByMachineId?" + param);
}

async UnMapUser(param){
  return await http.instance.post("/UnMapUser?" + param);
}
async DeleteMachines(param){
  return await http.instance.get("/DeleteMachines?" + param);
}
async GetUserListByCompanyId(param){
  return await http.instance.get("/GetUserListByCompanyId?" + param);
}
  
}



export default new DataService();
